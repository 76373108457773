@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-layer-image {
    background-image: url('../public/images/bg-layer.svg');
    background-size: 200% 100%;
    background-position: 0 0;
    background-repeat: repeat-x;
    animation: 
      move-bg 30s linear infinite,
      fade-in-out 20s ease-in-out infinite;
  }

  .animated-gradient {
    background-size: 400% 400%;
  }
}

@keyframes move-bg {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

@keyframes fade-in-out {
  0%, 100% { opacity: 0.15; }
  50% { opacity: 0.32; }
}

@keyframes gradient-bg {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}