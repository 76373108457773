/* Base Imports */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

/* Theme Transition */
:root {
  --theme-transition: background 0.7s ease, color 0.3s ease;
}

/* Core Design Tokens */
:root {
  /* Colors */
  --color-primary: #3b82f6;
  --color-secondary: #2f62b4;
  --color-accent: #ff7300;
  --color-background: #fafbff;
  --color-background-secondary: #ebebeb;
  --color-text-dark: #1f2937;
  --color-text-light: #f9fafb;

  /* Typography */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.25rem;

  /* Component Styles */
  --button-border-radius: 0.375rem;
  --button-border: 0px solid var(--color-primary);
  --button-hover-color: var(--color-secondary);
  --input-border: 1px solid #d1d5db;
  --input-focus-ring: 0 0 0 2px var(--color-primary);
}

/* Workout Component Base Variables */
:root {
  --color-workout-text: #ffffff;
  --color-workout-alttext: #fcd34d;
  --color-workout-daybg: rgba(30, 64, 175, 0.9);
  --color-workout-exerbg: rgba(30, 58, 138, 0.9);
  --color-workout-button-selected: #eab308;
  --color-workout-button-notselected: #2563eb;
}

/* Theme Definitions */
.theme-light {
  /* Theme Colors */
  --color-primary: var(--color-primary-light, #3b82f6);
  --color-secondary: var(--color-secondary-light, #2f62b4);
  --color-accent: var(--color-accent-light, #8b5cf6);
  --color-background: var(--color-background-light, #e6e6e6);
  --color-background-secondary: var(
    --color-background-secondary-light,
    #ffffff
  );
  --color-text-dark: var(--color-text-dark-light, #1f2937);
  --color-text-light: var(--color-text-light-light, #f9fafb);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #fcd34d;
  --color-workout-daybg: rgba(30, 64, 175, 0.9);
  --color-workout-exerbg: rgba(30, 58, 138, 0.9);
  --color-workout-button-selected: #eab308;
  --color-workout-button-notselected: #2563eb;
}

.theme-dark {
  /* Theme Colors */
  --color-primary: var(--color-primary-dark, #345986);
  --color-secondary: var(--color-secondary-dark, #3b82f6);
  --color-accent: var(--color-accent-dark, #e90303);
  --color-background: var(--color-background-dark, #1f2937);
  --color-background-secondary: var(--color-background-secondary-dark, #ffffff);
  --color-text-dark: var(--color-text-dark-dark, #f9fafb);
  --color-text-light: var(--color-text-light-dark, #1f2937);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #00ff4c;
  --color-workout-daybg: rgba(15, 15, 15, 0.9);
  --color-workout-exerbg: rgba(7, 7, 7, 0.9);
  --color-workout-button-selected: #00ff0d;
  --color-workout-button-notselected: #4b5563;
}

.theme-peach {
  /* Theme Colors */
  --color-primary: var(--color-primary-peach, #ff9a8b);
  --color-secondary: var(--color-secondary-peach, #ff6a88);
  --color-accent: var(--color-accent-peach, #fa0b37);
  --color-background: var(--color-background-peach, #fff6f0);
  --color-background-secondary: var(
    --color-background-secondary-peach,
    #fdf2ed
  );
  --color-text-dark: var(--color-text-dark-peach, #38292a);
  --color-text-light: var(--color-text-light-peach, #fff6f0);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #59ff91;
  --color-workout-daybg: rgba(243, 122, 91, 0.85);
  --color-workout-exerbg: rgba(248, 84, 42, 0.85);
  --color-workout-button-selected: #59ff91;
  --color-workout-button-notselected: #ffb4a2;
}

.theme-strawberry {
  /* Theme Colors */
  --color-primary: var(--color-primary-strawberry, #ff3366);
  --color-secondary: var(--color-secondary-strawberry, #ff6b6b);
  --color-accent: var(--color-accent-strawberry, #166805);
  --color-background: var(--color-background-strawberry, #fff0f3);
  --color-background-secondary: var(
    --color-background-secondary-strawberry,
    #fdeded
  );
  --color-text-dark: var(--color-text-dark-strawberry, #4a1f2f);
  --color-text-light: var(--color-text-light-strawberry, #fff0f3);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #78ffa0;
  --color-workout-daybg: rgba(165, 3, 51, 0.9);
  --color-workout-exerbg: rgba(165, 3, 52, 0.9);
  --color-workout-button-selected: #78ffa0;
  --color-workout-button-notselected: #ff3366;
}

.theme-8ball {
  /* Theme Colors */
  --color-primary: var(--color-primary-8ball, #333333);
  --color-secondary: var(--color-secondary-8ball, #666666);
  --color-accent: var(--color-accent-8ball, #ff1717);
  --color-background: var(--color-background-8ball, #000000);
  --color-background-secondary: var(
    --color-background-secondary-8ball,
    #fff4f4
  );
  --color-text-dark: var(--color-text-dark-8ball, #ffffff);
  --color-text-light: var(--color-text-light-8ball, #888888);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #ff6c32;
  --color-workout-daybg: #272727;
  --color-workout-exerbg: rgba(0, 0, 0, 0.9);
  --color-workout-button-selected: #ff6c32;
  --color-workout-button-notselected: #333333;
}

.theme-forest {
  /* Theme Colors */
  --color-primary: var(--color-primary-forest, #2255b3);
  --color-secondary: var(--color-secondary-forest, #20295a);
  --color-accent: var(--color-accent-forest, #d69404);
  --color-background: var(--color-background-forest, #f0f7f4);
  --color-background-secondary: var(
    --color-background-secondary-forest,
    #f0fffc
  );
  --color-text-dark: var(--color-text-dark-forest, #1b221c);
  --color-text-light: var(--color-text-light-forest, #dbdbdb);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #ffccff;
  --color-workout-daybg: #260842;
  --color-workout-exerbg: #351f49;
  --color-workout-button-selected: #33ccff;
  --color-workout-button-notselected: #444444;
}

.theme-sky {
  /* Theme Colors */
  --color-primary: var(--color-primary-sky, #4a90e2);
  --color-secondary: var(--color-secondary-sky, #5fb0e5);
  --color-accent: var(--color-accent-sky, #5900e7);
  --color-background: var(--color-background-sky, #f0f8ff);
  --color-background-secondary: var(--color-background-secondary-sky, #f2f9ff);
  --color-text-dark: var(--color-text-dark-sky, #1c1e22);
  --color-text-light: var(--color-text-light-sky, #ffffff);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #d9ecff;
  --color-workout-daybg: #2f4f7f;
  --color-workout-exerbg: rgba(0, 120, 255, 0.9);
  --color-workout-button-selected: #007fff;
  --color-workout-button-notselected: #333333;
}

.theme-camo {
  /* Theme Colors */
  --color-primary: var(--color-primary-sky, #063600);
  --color-secondary: var(--color-secondary-sky, #5fb0e5);
  --color-accent: var(--color-accent-sky, #1700e7);
  --color-background: var(--color-background-sky, #f0f8ff);
  --color-background-secondary: var(--color-background-secondary-sky, #f2f9ff);
  --color-text-dark: var(--color-text-dark-sky, #1c1e22);
  --color-text-light: var(--color-text-light-sky, #ffffff);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #f76300;
  --color-workout-daybg: rgba(41, 68, 10, 0.877);
  --color-workout-exerbg: rgba(28, 48, 6, 0.877);
  --color-workout-button-selected: #f76300;
  --color-workout-button-notselected: #87b97d;
}

.theme-usa {
  /* Theme Colors */
  --color-primary: var(--color-primary-sky, #063600);
  --color-secondary: var(--color-secondary-sky, #5fb0e5);
  --color-accent: var(--color-accent-sky, #1700e7);
  --color-background: var(--color-background-sky, #f0f8ff);
  --color-background-secondary: var(--color-background-secondary-sky, #f2f9ff);
  --color-text-dark: var(--color-text-dark-sky, #1c1e22);
  --color-text-light: var(--color-text-light-sky, #ffffff);

  /* Workout Component Colors */
  --color-workout-text: #ffffff;
  --color-workout-alttext: #ffffff;
  --color-workout-daybg: rgba(191, 10, 48, 0.85);
  --color-workout-exerbg: rgba(0, 40, 104, 0.85);
  --color-workout-button-selected: #f76300;
  --color-workout-button-notselected: #7e7e7e;
}

.theme-camowhite {
  /* Theme Colors */
  --color-primary: var(--color-primary-sky, #063600);
  --color-secondary: var(--color-secondary-sky, #5fb0e5);
  --color-accent: var(--color-accent-sky, #5900e7);
  --color-background: var(--color-background-sky, #f0f8ff);
  --color-background-secondary: var(--color-background-secondary-sky, #f2f9ff);
  --color-text-dark: var(--color-text-dark-sky, #1c1e22);
  --color-text-light: var(--color-text-light-sky, #ffffff);

  /* Workout Component Colors */
  --color-workout-text: #000000;
  --color-workout-alttext: #1a1abd;
  --color-workout-daybg: rgba(190, 190, 190, 0.88);
  --color-workout-exerbg: rgba(238, 238, 238, 0.9);
  --color-workout-button-selected: #a0a1d6;
  --color-workout-button-notselected: #acacac;
}

.theme-camopink {
  /* Theme Colors */
  --color-primary: var(--color-primary-sky, #063600);
  --color-secondary: var(--color-secondary-sky, #bd01c4);
  --color-accent: var(--color-accent-sky, #bb0083);
  --color-background: var(--color-background-sky, #f0f8ff);
  --color-background-secondary: var(--color-background-secondary-sky, #f2f9ff);
  --color-text-dark: var(--color-text-dark-sky, #1c1e22);
  --color-text-light: var(--color-text-light-sky, #ffffff);

  /* Workout Component Colors */
  --color-workout-text: #000000;
  --color-workout-alttext: #53004f;
  --color-workout-daybg: rgba(190, 190, 190, 0.88);
  --color-workout-exerbg: rgba(238, 238, 238, 0.9);
  --color-workout-button-selected: #a0a1d6;
  --color-workout-button-notselected: #acacac;
}

/* Background Styles */
.workout-background {
  transition: var(--theme-transition);
}

/* Theme-specific background styles */
.theme-light .workout-background {
  background: linear-gradient(to bottom, #3b82f6, #1e40af);
}

/* Theme-specific background styles */
.theme-camowhite .workout-background {
  background-image: url('../../public/images/themes/camowhite.jpg');
  background-size: 550px 550px;
  background-repeat: repeat;
  background-position: center;
}

.theme-camopink .workout-background {
  background-image: url('../../public/images/themes/camopink.jpg');
  background-size: 550px 550px;
  background-repeat: repeat;
  background-position: center;
}

/* Theme-specific background styles */
.theme-camo .workout-background {
  background-image: url('../../public/images/themes/camogreen.jpg');
  background-size: 550px 550px;
  background-repeat: repeat;
  background-position: center;
}

.theme-usa .workout-background {
  background-image: url('../../public/images/themes/usa.jpg');
  background-size: 550px 550px;
  background-repeat: repeat;
  background-position: center;
}

.theme-dark .workout-background {
  background: linear-gradient(to bottom, #474747, #303030);
}

.theme-peach .workout-background {
  /* background: linear-gradient(to bottom, #ff653e, #ff8d70); */
  background-image: url('../../public/images/themes/peach.jpg');
  background-size: 550px 550px;
  background-repeat: repeat;
  background-position: center;
}

.theme-strawberry .workout-background {
  background-image: url('../../public/images/themes/strawberry.jpg');
  background-size: 350px 350px;
  background-repeat: repeat;
  background-position: center;
}

.theme-8ball .workout-background {
  background: linear-gradient(to bottom, #252525, #0f0f0f);
}

.theme-forest .workout-background {
  background: linear-gradient(to bottom, #1a1d23, #131418);
}

.theme-sky .workout-background {
  background: linear-gradient(to bottom, #212121, #1a1d23);
}

.theme-sunset .workout-background {
  background: linear-gradient(to bottom, #0097a7, #0080a5);
}

/* Component Classes */
.btn-primary {
  background-color: var(--color-primary);
  border: var(--button-border);
  border-radius: var(--button-border-radius);
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: var(--font-size-base);
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

/* Media Queries */
@media (max-width: 640px) {
  :root {
    --font-size-xs: 0.625rem;
    --font-size-sm: 0.75rem;
    --font-size-base: 0.875rem;
    --font-size-lg: 1rem;
    --font-size-xl: 1.125rem;
    --font-size-2xl: 1.25rem;
    --font-size-3xl: 1.5rem;
    --font-size-4xl: 1.875rem;
  }
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning-background {
  animation: spin 20s linear infinite;
}

/* Responsive text sizes */
@media (max-width: 640px) {
  :root {
    --font-size-xs: 0.625rem;
    --font-size-sm: 0.75rem;
    --font-size-base: 0.875rem;
    --font-size-lg: 1rem;
    --font-size-xl: 1.125rem;
    --font-size-2xl: 1.25rem;
    --font-size-3xl: 1.5rem;
    --font-size-4xl: 1.875rem;
  }
}

.workout-background {
  background: var(--color-workout-mainbg-gradient);
  background-image: var(--workout-background-image);
  background-size: 400px;
  background-repeat: repeat;
  background-position: center;
}

.btn-primary {
  background-color: var(--color-primary);
  border: var(--button-border);
  border-radius: var(--button-border-radius);
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.btn-disabled {
  background-color: var(--color-secondary);
  border: var(--button-border);
  border-radius: var(--button-border-radius);
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: var(--color-secondary);
}

.btn-special {
  background-image: var(--gradient-primary);
  border: var(--button-border);
  border-radius: var(--button-border-radius);
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  transition: background-image 0.5s ease;
}

.btn-secondary:hover {
  background-color: var(--color-accent);
}

.btn-special:hover {
  background-image: var(--gradient-secondary);
}

.gradient-box {
  background-image: var(--gradient-primary);
  border-radius: var(--button-border-radius);
  padding: 1rem;
  color: white;
}

.input-primary {
  appearance: none;
  border: var(--input-border);
  border-radius: var(--button-border-radius);
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-primary:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: var(--input-focus-ring);
}

.text-primary {
  color: var(--color-primary);
}

.text-dark {
  color: var(--color-text-dark);
}

.text-light {
  color: var(--color-text-light);
}

.bg-light {
  background-color: var(--color-background);
}

.bg-light-secondary {
  background-color: var(--color-background-secondary);
}

.bg-alternate {
  background-color: var(--color-primary);
}

a {
  color: var(--color-text-dark);
}

a:hover {
  color: var(--color-text-light);
}

.ad-container {
  height: 4rem; /* 64px, equivalent to h-16 */
  background-image: var(
    --gradient-secondary
  ); /* Using the secondary gradient for variety */
}

.ad-text {
  font-size: 1.125rem; /* Equivalent to text-lg in Tailwind */
  font-weight: 700; /* Equivalent to font-bold in Tailwind */
}

/* Add these new classes to your existing CSS file */

.cookie-banner {
  background-color: var(--color-background);
  opacity: 0.9;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.btn-secondary {
  background-color: var(--color-secondary);
  border-radius: var(--button-border-radius);
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.btn-red {
  background-color: red;
  border: 1px solid red;
  border-radius: var(--button-border-radius);
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.btn-tertiary {
  background-color: #d1d5db;
  border: 1px solid #d1d5db;
  border-radius: var(--button-border-radius);
  color: var(--color-text-dark);
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-tertiary:hover {
  background-color: #9ca3af;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning-background {
  animation: spin 20s linear infinite;
}
